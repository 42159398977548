@import '../../styles/colors';
@import '../../styles/base';

.input-box {
  display: flex;
  position: relative;
  flex-direction: column;

  &.focused {
    border-color: $blue;
  }

  .eye {
    display: block;
    margin-right: 8px;
  }

  .icon {
    display: block;
    margin: 2px 8px 0 8px;
  }

  .copy {
    cursor: pointer;
  }

  label {
    white-space: nowrap;
    display: block;
    font: 400 13px/13px $font-family;
    color: $text-light-color;
    margin-bottom: 8px;

    &.error {
      color: $alert-color;
    }

    span {
      color: $placeholder-color;
    }
  }

  .input-wrapper {
    @extend %border;
    position: relative;
    height: 48px;
    padding: 0 8px;
    background-color: $white;
    color: #333;
    display: flex;
    align-items: center;

    &:focus-within {
      border-color: $purple;
    }

    &.secondary {
      border-width: 0;
      background-color: $super-light-gray;
    }

    &.error {
      border: 1px solid $alert-color;
    }

    &.clean {
      border-width: 0;
      background-color: transparent;
    }

    span {
      &.right-text {
        font: 600 14px/14px $font-family;
        margin-right: 8px;
        color: $black-berry;
        cursor: default;
      }
    }
  }

  input {
    display: block;
    min-width: 100px;
    flex: 1 1 100%;
    height: 100%;
    border-width: 0;
    font: 14px/14px $font-family;
    letter-spacing: 0.02em;
    caret-color: $blue;
    color: $dark-gray;
    margin: 0px 8px;

    &:read-only {
      user-select: none;
    }

    &.secondary {
      background-color: $super-light-gray;
    }

    &.clean {
      background-color: transparent;
    }

    &.zip {
      padding-left: 40px;
      padding-right: 0;
      background: url('../../assets/icon-zip.svg') 16px center no-repeat;
      background-size: 16px;
    }

    &.phone {
      padding: 0 13px 0 51px;
      background: url('../../assets/icon-flag-japan.svg') 13px center no-repeat;
      background-size: 30px 20px;
    }

    &.pin {
      padding-left: 40px;
      background: url('../../assets/icon-lock-gray.svg') 16px center no-repeat;
      background-size: 16px;
    }

    &.left-icon {
      margin-left: 0px;
    }

    &.right-icon {
      margin-right: 0px;
    }

    &:focus-visible {
      outline: 0;
    }
  }

  .error-wrapper {
    margin-top: 8px;
    border-radius: 4px;
    padding: 8px 8px 8px 40px;
    background: $light-pink url('../../assets/icon-exclamation-pink.svg') 16px
      8px no-repeat;
    background-size: 16px;

    p {
      font: 400 13px/16px $font-family;
      color: $alert-color;
    }
  }
}

.popup-content {
  width: auto;
  font: 600 13px/16px $font-family;
  height: 16px;
}

.copied-content {
  display: flex;
  align-items: center;
  height: 16px;

  > *:not(:first-child) {
    margin-left: 8px;
  }
}
