@import '../styles/base';

:root {
  // fonts
  --fallback-fonts: '-apple-system', BlinkMacSystemFont, 'Hiragino Sans', Osaka,
    sans-serif;
  --font-family: 'Noto Sans JP', var(--fallback-fonts);
  --font-family-number: 'Filson Pro Numbers', 'Noto Sans JP',
    var(--fallback-fonts);
  --font-family-mono: 'SF Mono', monospace;
  --base-font-size: 16px;
  // wrapper vars
  --max-wrapper-width: 1130px;
}

.root {
  flex: 1;
  background: $white;
  min-width: $min-device-width;
  display: flex;

  > .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - #{$ribbon-height});
    position: absolute;
    top: $ribbon-height;
    left: $sidebar-width;
    width: calc(100% - #{$sidebar-width});
  }

  aside {
    background: $black-berry;
    width: $sidebar-width;
    flex: 0 0 $sidebar-width;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    z-index: 1;

    > * {
      &:first-child {
        padding: 24px;
      }
    }

    nav {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      .top {
        > a {
          @extend %sidebar-item;
        }

        a,
        button {
          padding: 0 24px;
        }
      }

      .bot {
        .block {
          border-top: 1px solid $border-light-color;
        }

        .block > a {
          @extend %sidebar-item;
          padding: 16px 24px;
          height: auto;
        }

        .switch-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 72px;

          .lang-switcher {
            border: 1px solid #f1f3ff;
            border-radius: 6px;
            color: #f1f3ff;
            cursor: pointer;
            font: 700 14px/32px $font-family;
            padding: 0 16px;
            text-decoration: none;
            opacity: 0.5;

            &:focus {
              outline: 0;
            }

            &:hover {
              border-color: #fff;
              color: #fff;
              opacity: 1;
            }
          }
        }
      }

      button {
        &.profile {
          @extend %sidebar-item;
          margin-top: auto;
          padding: 25px 24px;
          height: 72px;
          width: 100%;
        }
      }
    }
  }

  main {
    padding: 48px $main-padding 48px $sidebar-width + $main-padding;
    min-width: 960px;
    width: 100%;

    > h1 {
      font: 600 28px/36px $font-family;
      margin-bottom: 24px;
    }
  }
}

.logo {
  margin: 0 auto 40px;
}

.strip-container {
  position: absolute;
  width: calc(100% - #{$sidebar-width});
  left: $sidebar-width;
  border-top: 3px solid $test-color;
  z-index: 10000;
  pointer-events: none;

  .strip {
    display: flex;
    justify-content: center;

    &::before {
      content: url('../assets/strip-before.svg');
      width: 20px;
      height: 26px;
    }

    &::after {
      content: url('../assets/strip-after.svg');
      width: 20px;
      height: 26px;
    }

    > span {
      font: 700 12px/20px $font-family;
      display: flex;
      align-items: center;
      background-color: $test-color;
      color: $white;
      padding: 0px 16px;
      user-select: none;

      > img {
        margin-right: 4px;
        user-select: none;
      }
    }
  }
}
