@import '../../styles/base';

.layout {
  @extend %setting-layout;
}

.container {
  @extend %setting-container;
  @extend %border-bottom;

  > section {
    @extend %section;

    > * {
      margin-bottom: 16px;

      > * {
        > input {
          font-weight: bold;
        }
      }
    }
  }
}

.contact {
  font: 15px/28px $font-family;
  color: $text-light-color;
  padding: 24px 0px;

  a {
    text-decoration: none;
  }
}

.contract {
  @extend %border-bottom;
  font: 13px/13px $font-family;
  color: $text-light-color;
  padding-bottom: 24px;

  time {
    font: 14px/14px $font-family;
    color: $dark-gray;
    display: block;
    margin-top: 16px;
  }
}
