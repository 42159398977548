@import '../../styles/colors';
@import '../../styles/base';

.input-box {
  display: flex;
  position: relative;
  flex-direction: column;

  &.focused {
    border-color: $blue;
  }

  label {
    display: block;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    font-size: 15px;
    line-height: 24px;
    color: $text-medium-color;

    &.error {
      color: $alert-color;
    }

    .icon {
      display: inline-block;
      margin: 0 8px 0 0;
      vertical-align: middle;
    }
  }

  .input-wrapper {
    position: relative;
    width: 100%;
    height: 24px;
    background-color: $white;
    color: $text-medium-color;
    display: flex;
    align-items: center;

    input[type='checkbox'] {
      appearance: none;
      opacity: 0;
      border: 0;
      width: 0;
      position: absolute;
      background: transparent;
    }
  }

  .error-wrapper {
    margin-top: 8px;
    background: $light-pink url('../../assets/icon-exclamation-pink.svg') 16px
      8px no-repeat;
    background-size: 16px;
    padding: 8px 8px 8px 40px;

    p {
      font: 400 13px/16px $font-family;
      color: $alert-color;
    }
  }
}
