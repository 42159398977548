@import '../../styles/colors';
@import '../../styles/base';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 0 auto 32px;
  }

  div {
    display: flex;
    font: 700 32px/44px $font-family;
    margin: 0 auto 72px;

    > span {
      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }

  button {
    width: 328px;
  }
}
