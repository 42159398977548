@import '../../styles/base';

.receipt-container {
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
    cursor: pointer;

    .btn {
      &.expanded {
        transform: rotate(90deg);
      }
    }
  }

  hr {
    margin: 16px 0;
    border: none;
    height: 1px;
    background-color: $separator-color;

    &.major {
      background-color: $text-light-color;
    }
  }
}

.receipt {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font: 500 13px/13px $font-family;
  color: $text-dark-color;

  > * {
    &:first-child {
      display: flex;
      flex-direction: column;
      gap: 6px;
      color: $dark-gray;

      .note {
        font-weight: normal;
        color: $text-light-color;
      }
    }

    &:last-child {
      display: flex;
      flex-direction: column;
      gap: 6px;
      align-items: flex-end;
      color: $text-light-color;

      b {
        font: 500 16px/16px $font-family;
        color: $text-dark-color;
      }
    }
  }
}

.coupon-type {
  padding-bottom: 2px;
  font: 700 10px/13px $font-family;
  color: $text-light-color;
}

.total {
  font: 18px/18px $font-family;

  > * {
    &:first-child {
      color: $dark-gray;
      font: 600 18px/18px $font-family;
    }

    &:last-child {
      font: 600 18px/18px $font-family;
      color: $text-dark-color;
    }
  }
}
