@import '../../styles/base';

tr {
  &.promotion-code-order-row {
    cursor: pointer;

    td {
      &.order-id {
        width: auto;
        padding: 0px;
        width: 330px;

        > div {
          width: auto;

          input {
            font: 300 13px/22px $font-family-mono;
            color: $text-light-color;
          }
        }
      }

      &.amount {
        text-align: right;
      }
    }
  }
}
