.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  button {
    width: 80px;
    margin-left: 16px;
  }
}
