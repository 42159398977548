@import '../../styles/colors';

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 38px;
  font-size: 13px;
  line-height: 38px;
  text-align: center;
  color: $text-light-color;
}
