@import '../../styles/base';

.header {
  display: flex;
  justify-content: center;
  position: relative;
  height: 44px;
  padding-bottom: 12px;
  border-bottom: 1px solid $light-gray;
  margin-bottom: 12px;

  > nav {
    display: flex;
    align-items: center;
  }

  .btn-back {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 24px;
    width: auto;
  }

  h2 {
    padding: 0 24px;
    background: $super-light-gray;
    margin: 0 16px;
    font: 600 20px/32px $font-family;
    border-radius: 4px;
  }
}

// TODO: Add this back when the endpoint has the information for this
// .btn-prev,
// .btn-next {
//   font-size: 0;
//   color: transparent;
//   width: 24px;
//   height: 24px;
// }

// .btn-next {
//   background: url('../../assets/icon-next.svg') center no-repeat;
//   background-size: contain;

//   &:hover,
//   &.active {
//     background-image: url('../../assets/icon-next-active.svg');
//   }

//   &.disabled {
//     pointer-events: none;
//   }
// }

// .btn-prev {
//   @extend .btn-next;
//   transform: rotate(180deg);
// }

.summary,
.breakdown {
  h3 {
    font: 600 16px/28px $font-family;
    margin-bottom: 16px;
    width: 100%;
  }
}

.summary {
  display: flex;
  padding-top: 24px;

  > .payout,
  > .transfer {
    flex: 1 1 calc(50% - 4px);

    > .content {
      border: 1px solid $light-gray;
      border-radius: 8px;
      height: 93px;
    }
  }

  // 残高の概要
  .payout {
    margin-right: 8px;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .amount {
        font: 600 24px/32px $font-family;
        margin-bottom: 8px;
      }

      .meta {
        display: flex;
        align-items: center;

        .label {
          color: $text-light-color;
          margin-right: 12px;
          font: 600 13px/16px $font-family;
        }
      }
    }
  }

  // 振込情報
  .transfer {
    .content {
      display: flex;
      align-items: center;
      justify-content: center;

      .separator {
        display: block;
        height: 100%;
        width: 1px;
        background: $light-gray;
        padding: 0 4px;
        margin: 0 16px;
      }

      .label {
        font: 300 13px/1 $font-family;
        color: $text-light-color;
        margin-bottom: 8px;
      }

      .value {
        font: 600 13px/16px $font-family;
      }

      .date {
        min-width: 140px;
        border-right: 1px solid $light-gray;
        margin-right: 20px;
        height: 37px;
      }

      .bank,
      .branch,
      .account {
        min-width: 80px;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}

.breakdown {
  display: flex;
  padding-top: 32px;

  .total,
  .refunded,
  .transaction,
  .payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $super-light-gray;
    height: 93px;
    border-radius: 8px;

    .label {
      color: $text-light-color;
      font: 300 13px/22px $font-family;
    }

    .value {
      font: 600 16px/32px $font-family;
      margin-bottom: 8px;
    }
  }

  // 売上の概要
  .sales {
    flex: 1;
    margin-right: 8px;

    .content {
      display: flex;

      > div {
        flex: 1;
      }

      .total {
        margin-right: 8px;
      }
    }
  }

  // 手数料の詳細
  .fee {
    flex: 1;

    .content {
      display: flex;

      > div {
        flex: 1;
      }
    }

    .transaction {
      margin-right: 8px;
    }
  }
}

.report {
  display: flex;
  flex-direction: column;

  .separator {
    background-color: $light-gray;
    height: 1px;
    margin: 32px 0 24px 0;
  }

  .dl-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 24px;
  }

  .download {
    height: 32px;
    width: fit-content;
    padding: 0 24px;
    font: 600 14px/16px $font-family;
    align-self: flex-end;
  }
}

@media only screen and (max-width: 1300px) {
  .summary,
  .breakdown {
    flex-direction: column;

    > .payout,
    > .transfer {
      width: 100%;
    }

    > .transfer,
    > .fee {
      margin-top: 16px;
    }
  }
}
