@import '../../styles/base';

.navigation-bar {
  @extend %border-bottom;
  display: flex;
  padding-bottom: 12px;
  align-items: center;
  max-width: 1080px;
  min-width: 800px;

  > button {
    width: 82px;
  }

  > h1 {
    font: 600 24px/32px $font-family;
    margin-left: 24px;
    margin-right: 8px;

    &.inactive {
      color: $disable-color;
    }
  }

  > *:last-child {
    margin-left: auto;

    > div {
      > input {
        font: 300 13px/22px $font-family-mono;
      }
    }
  }
}

.coupon-detail-container {
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  min-width: 800px;

  > section {
    @extend %section;
    width: 100%;
    justify-content: flex-start;

    > h2 {
      margin: 0;
      padding: 0 0 16px;
    }

    > button {
      &.create-promotion-btn {
        width: auto;
        position: absolute;
        right: 0px;
        padding: 0 24px;
      }
    }

    // First section
    &:first-child {
      height: 22px;
      margin: 12px 0 24px;

      i {
        font: 300 13px/22px $font-family;
        width: auto;
        height: 100%;
        margin-left: 100px;
        color: $text-light-color;
      }

      > div {
        margin-left: auto;
        height: 100%;
        width: auto;

        input {
          font: 300 13px/22px $font-family-mono;
          color: $text-light-color;
        }
      }
    }

    &:not(:first-child) {
      margin-bottom: 32px;

      // Second section
      &.details {
        flex-direction: row;

        > div {
          flex: 1;
          width: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: $super-light-gray;
          border-radius: 8px;
          height: 94px;

          b {
            font: 600 24px/32px $font-family;
          }

          i {
            font: 300 13px/22px $font-family;
            margin-top: 8px;
          }

          time {
            font: 600 16px/28px $font-family;

            > span {
              &:nth-of-type(2) {
                font: 600 13px/16px $font-family;
                margin-left: 16px;
              }
            }
          }

          > span {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &.expiry-date {
              color: $purple;
            }

            &.expired {
              color: $cerise;
            }

            &:not(:last-of-type) {
              position: relative;

              &::after {
                content: '';
                background-color: $light-gray;
                width: 1px;
                height: 44px;
                position: absolute;
                right: 0px;
              }
            }
          }

          &:last-of-type {
            flex-direction: row;
            flex: 2;
          }

          &:not(:last-of-type) {
            margin-right: 8px;
          }

          &.finished {
            color: $cerise;
          }
        }

        .discount-detail {
          display: flex;

          strong {
            margin-left: 12px;
            border-left: 1px solid $light-gray;
            padding-left: 12px;
            height: 32px;
            line-height: 32px;
          }
        }
      }
    }

    button {
      &.create-promotion-code {
        width: 216px;
        margin-top: 24px;
      }
    }
  }
}

.meta-container {
  > div {
    font: 400 13px/13px $font-family;
    background-color: $super-light-gray;
    color: $text-light-color;
    border-radius: 6px;
    display: flex;
    margin-bottom: 8px;
    padding: 8px 24px;

    > * {
      width: 50%;
    }

    :first-child {
      font: 600 13px/16px $font-family;
      color: $dark-gray;
      width: 200px;
    }
  }
}

.table {
  @extend %table;
  min-width: 800px;
  max-width: 1080px;

  thead {
    tr {
      th {
        text-align: left;

        &.redemptions {
          text-align: right;
        }

        &.promotion-code {
          width: 300px;
        }
      }
    }
  }
}
