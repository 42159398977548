@import '../../styles/base';

.radio-btn-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 24px;
  margin-right: 16px;

  input {
    cursor: pointer;
    background-color: $white;
    opacity: 0;
    height: 0;
    width: 0;
  }

  > i {
    cursor: pointer;
    position: relative;
    font: 400 15px/24px $font-family;

    &::before {
      content: '';
      background-color: $white;
      position: absolute;
      left: -32px;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      border: 1px solid $purple;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
    }
  }

  input[type='radio'] {
    &:checked {
      ~ i {
        &::before {
          border-width: 8px;
          height: 8px;
          width: 8px;
        }
      }
    }
  }
}
