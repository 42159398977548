@import '../../styles/base';

.payout {
  cursor: pointer;

  &:hover {
    background-color: $super-light-gray;
  }
}

.period {
  @extend %period;
}

.amount {
  text-align: right;

  &.negative {
    color: $alert-color;
  }
}
