@import '../../styles/base';

.capsule {
  @extend %rounded-rectangle;
  font: 600 12px/20px $font-family;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
  color: $text-light-color;
  background-color: $light-gray;
  border-radius: 4px;
  padding: 0 16px;
  display: inline-block;

  &.order {
    width: 100%;
    min-width: 96px;
  }

  &.succeeded {
    color: $green;
    background-color: #ddf6e2;
  }

  &.payment {
    width: 100%;
    min-width: 80px;
  }

  &.mode {
    height: 24px;
    min-width: 96px;
    font: 600 12px/20px $font-family;
    padding: 2px 0;
  }

  &.sponsored {
    color: #5468fb;
    background-color: #e7f0ff;
  }

  &.invalid {
    color: $text-light-color;
    background-color: $super-light-gray;
  }

  &.dark-gray {
    color: #fff;
    background-color: $text-light-color;
  }

  &.green {
    color: $green;
    background-color: #ddf6e2;
  }

  &.tag-green {
    color: $tag3;
    background-color: $tag3-light;
  }

  &.yellow {
    color: $white;
    background-color: $yellow;
  }

  &.orange {
    color: $white;
    background-color: #fd7a64;
  }

  &.blue {
    color: $white;
    background-color: $very-light-blue;
  }

  &.cerise {
    color: $error-color;
    background-color: $light-pink;
  }

  &.small {
    font: 600 10px/20px $font-family;
  }
}
