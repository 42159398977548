@import '../../styles/base';

.coupon {
  cursor: pointer;

  &:hover {
    background-color: $super-light-gray;
  }

  > td {
    font: 400 13px/22px $font-family;
    color: $dark-gray;
    padding: 0 24px;

    &.name {
      font: 600 13px/16px $font-family;
      width: 256px;
      display: flex;
      align-items: center;

      i {
        margin-left: 8px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
      }

      &.inactive {
        color: #6b748d;
      }
    }

    &.condition {
      color: $text-light-color;
    }

    &.redemption {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > i {
        margin-right: 8px;
      }
    }

    &.finished {
      color: $error-color;
    }

    &:last-child {
      width: 164px;
      white-space: nowrap;
      color: $text-medium-color;

      > time {
        > span:last-child {
          margin-left: 16px;
        }

        &.expired {
          color: $cerise;
        }
      }
    }
  }
}
