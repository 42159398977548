@import '../../styles/base';

tr {
  &.promotion-code-row {
    cursor: pointer;

    td {
      &.code {
        font: 600 13px/16px $font-family;

        > i {
          margin-left: 8px;
        }

        &.inactive {
          color: $text-light-color;
        }
      }

      &.redemption {
        text-align: right;

        > i {
          margin-right: 8px;
        }

        &.finished {
          color: $cerise;
        }
      }

      &.promotion-code-id {
        text-align: left;
        width: auto;

        > div {
          width: auto;
          margin-left: -16px;

          input {
            font: 300 13px/22px $font-family-mono;
            color: $text-light-color;
            margin-right: 10px;
          }
        }
      }

      &.expiry-date {
        width: 164px;
        white-space: nowrap;
        color: $text-medium-color;

        &.expired {
          color: $cerise;
        }

        time {
          display: flex;
          flex-direction: row;
          gap: 16px;
        }
      }
    }
  }
}
