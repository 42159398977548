@import '../../styles/colors';
@import '../../styles/base';

.container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin: 48px auto;
  width: 480px;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 0 auto 48px;
  }
}

.content {
  margin: 0 auto;
  width: 100%;
  max-width: 480px;

  h2 {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    color: $dark-gray;
  }

  p {
    margin-bottom: 12px;
    font-size: 15px;
    line-height: 22px;

    &:last-of-type {
      margin-bottom: 48px;
    }

    &.note {
      margin-top: 16px;
      font-size: 15px;
      line-height: 28px;
      color: $text-light-color;

      button {
        color: $link-color;
        cursor: pointer;
      }
    }
  }
}

.form {
  width: 100%;
  max-width: 400px;

  fieldset {
    margin-bottom: 16px;

    > * {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .form-actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.remember-me {
  margin-top: 4px;
}

.forgot-password {
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  margin-top: 12px;
  display: block;
}
