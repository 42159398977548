@import '../../styles/base';

.resend-countdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  padding: 12px;
  height: 48px;
  font: 400 13px/16px $font-family;
  color: #6b748d;
  background: #f8f9fc;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }

  .mono {
    font: 400 13px/16px $font-family-mono;
  }
}
