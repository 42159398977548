@import '../../styles/base';

.toggle-container {
  margin: 16px 0px;

  .desc {
    font: 400 13px/22px $font-family;
    color: $dark-gray;
    width: 200px;
  }
}

.inner-toggle {
  font: 600 14px/24px $font-family;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  > * {
    cursor: default;
  }

  > div {
    padding: 0 4px;
  }

  .inactive {
    color: $disable-color;
  }
}
