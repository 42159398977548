@import '../../styles/base';

.layout {
  @extend %setting-layout;
}

.container {
  @extend %setting-container;

  > section {
    @extend %section;

    > * {
      margin-bottom: 16px;
    }
  }
}

.contact {
  @extend %border-top;
  font: 400 15px/28px $font-family;
  color: $text-light-color;
  padding: 24px 0px;

  a {
    text-decoration: none;
  }
}
