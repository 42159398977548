.wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.popup-content {
  font-size: 16px;
  white-space: nowrap;

  img {
    display: inline;
    margin: -2px 4px 0 0;
    vertical-align: top;
  }
}
