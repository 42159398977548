@import '../../styles/base';

.top-bar {
  display: flex;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 24px;

  > h1 {
    font: 600 28px/36px $font-family;
  }

  > *:last-child {
    margin-left: auto;
    min-width: 328px;
  }
}

.table {
  @extend %table;
  min-width: 960px;

  thead {
    tr {
      th {
        text-align: left;

        &.amount {
          // text-align: right;

          > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 4px;
          }
        }

        &.status {
          text-align: center;
          width: 96px;
        }

        .ref-switch {
          width: 270px;
          cursor: pointer;

          &.show-ref {
            &:hover {
              > .id {
                color: $link-color;

                &::before {
                  background-color: $link-color;
                }
              }

              > .ref {
                &::before {
                  background-color: $placeholder-color;
                }
              }
            }

            > .ref {
              color: $dark-gray;

              &::before {
                background-color: $link-hover;
              }
            }
          }

          &.show-id {
            &:hover {
              > .id {
                &::before {
                  background-color: $placeholder-color;
                }
              }

              > .ref {
                color: $link-color;

                &::before {
                  background-color: $link-color;
                }
              }
            }

            > .id {
              color: $dark-gray;

              &::before {
                background-color: $link-hover;
              }
            }
          }

          > span {
            color: $placeholder-color;
            position: relative;
            cursor: pointer;
            letter-spacing: 0.01em;
            transition: color 0.3s ease;
            will-change: color;

            &::before {
              transition: background-color 0.3s ease;
              will-change: background-color;
              content: '';
              width: 5px;
              height: 8px;
              background-color: $placeholder-color;
              mask: url('../../assets/icon-switch-left.svg');
              mask-size: contain;
              @extend %vertical-center;
            }

            &.id {
              padding-right: 11px;

              &::before {
                right: 1px;
              }
            }

            &.ref {
              padding-left: 11px;

              &::before {
                left: 1px;
                transform: scale(-1) translateY(50%);
              }
            }
          }
        }
      }
    }
  }
}

.filters {
  display: flex;
  margin: 8px 0;

  > i {
    font: 600 14px/12px $font-family;
    text-transform: uppercase;
    color: $dark-gray;
    text-decoration: none;
    padding: 10px 0;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 36px;
    }

    &:hover,
    &.active {
      color: #4456dd;
    }
  }
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  min-height: 120px;
}

.popup-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font: normal 13px/18px $font-family;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 24px;
  }
}
