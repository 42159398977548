%big-numbers-d1 {
  font: 400 56px/56px var(--font-family-number);
  letter-spacing: -0.06em;
  font-feature-settings: 'palt' on !important;
}

%big-numbers-d2 {
  font: 400 40px/56px var(--font-family-number);
  letter-spacing: -0.05em;
  font-feature-settings: 'palt' on !important;
}

%headline-b1 {
  font: bold 60px/72px var(--font-family);
  letter-spacing: -0.04em;
  font-feature-settings: 'palt' on !important;
}

%headline-b2 {
  font: bold 52px/64px var(--font-family);
  letter-spacing: -0.04em;
  font-feature-settings: 'palt' on !important;
}

%headline-h1 {
  font: bold 44px/56px var(--font-family);
  letter-spacing: -0.04em;
  font-feature-settings: 'palt' on !important;
}

%headline-h2 {
  font: bold 36px/48px var(--font-family);
  letter-spacing: -0.03em;
  font-feature-settings: 'palt' on !important;
}

%headline-h3 {
  font: bold 28px/36px var(--font-family);
  letter-spacing: -0.03em;
  font-feature-settings: 'palt' on !important;
}

%headline-h4 {
  font: bold 22px/30px var(--font-family);
  letter-spacing: -0.02em;
  font-feature-settings: 'palt' on !important;
}

%headline-h5 {
  font: bold 16px/23px var(--font-family);
  letter-spacing: -0.01em;
  font-feature-settings: 'palt' on !important;
}

%headline-h13 {
  font: bold 13px/13px var(--font-family);
  font-feature-settings: 'palt' on !important;
}

%body-text {
  font: 400 16px/28px var(--font-family);
}

%body-large-text {
  font: 400 18px/30px var(--font-family);
  letter-spacing: -0.01em;
}

%body-small-text {
  font: 400 14px/18px var(--font-family);
  letter-spacing: -0.01em;
}

%body-button {
  font: 500 16px/30px var(--font-family);
}

%body-caption {
  font: 400 13px/20px var(--font-family);
  text-transform: uppercase;
  font-feature-settings: 'palt' on !important;
}

// Mobile
@mixin mobile-big-numbers-d1 {
  font: 400 64px/64px var(--font-family-number);
  letter-spacing: -0.05em;
}

@mixin mobile-big-numbers-d2 {
  font: 400 40px/40px var(--font-family-number);
  letter-spacing: -0.05em;
}

@mixin mobile-headline-b1 {
  font: bold 44px/52px var(--font-family);
  letter-spacing: -0.04em;
}

@mixin mobile-headline-b2 {
  font: bold 30px/40px var(--font-family);
  letter-spacing: -0.04em;
}

@mixin mobile-headline-h1 {
  font: bold 26px/36px var(--font-family);
  letter-spacing: -0.04em;
}

@mixin mobile-headline-h2 {
  font: bold 24px/32px var(--font-family);
  letter-spacing: -0.03em;
}

@mixin mobile-headline-h3 {
  font: bold 21px/32px var(--font-family);
  letter-spacing: -0.01em;
}

@mixin mobile-headline-h4 {
  font: bold 18px/24px var(--font-family);
  letter-spacing: -0.01em;
}

@mixin mobile-headline-h5 {
  font: bold 15px/21px var(--font-family);
  letter-spacing: -0.01em;
}

@mixin mobile-headline-h13 {
  font: bold 11px/11px var(--font-family);
}

@mixin mobile-body-text {
  font: 400 15px/23px var(--font-family);
}

@mixin mobile-body-large-text {
  font: 400 18px/28px var(--font-family);
  letter-spacing: -0.01em;
}

@mixin mobile-body-small-text {
  font: normal 12px/18px var(--font-family);
  letter-spacing: -0.01em;
}

@mixin mobile-body-button {
  font: 500 15px/24px var(--font-family);
}

@mixin mobile-body-caption {
  font: 400 13px/20px var(--font-family);
  text-transform: uppercase;
  font-feature-settings: 'palt' on !important;
}

@mixin mobile-app-text-header-h16 {
  font: 700 16px/16px var(--font-family);
}
