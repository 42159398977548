@import '../../styles/base';

.toggle-container {
  margin: 0px;
  padding: 16px 24px;

  .popup-trigger {
    > * {
      // Popup container
      &:first-child {
        transform: translate(0px, -122px);
      }

      // trigger-item
      &:last-child {
        > * {
          // arrow border
          &:nth-child(2) {
            transform: translate(80px, -34px) translateX(+7px);
          }

          // arrow
          &:last-child {
            transform: translate(80px, -32px) translateX(+12px);
          }
        }
      }
    }

    .desc {
      font: 400 13px/22px $font-family;
      color: $dark-gray;
      width: 200px;
    }
  }
}

.inner-toggle {
  font: 700 14px/24px $font-family;
  display: flex;
  color: $white;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  > * {
    cursor: default;
  }

  .inactive {
    opacity: 0.5;
  }
}
