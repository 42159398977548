@import '../../styles/base';

.top-bar {
  display: flex;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 24px;
  min-width: 960px;

  > h1 {
    font: 600 28px/36px $font-family;
  }

  > .cta {
    width: auto;
    padding: 0px 18px;
  }
}

.table {
  @extend %table;
  min-width: 960px;

  thead {
    tr {
      th {
        text-align: left;
      }
    }
  }

  tbody {
    td {
      &.amount {
        font-weight: 600;
      }

      &.inactive {
        color: #c7c8cc;
      }

      &:global(.align-center) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.popup-content {
  width: auto;
  font: 600 13px/16px $font-family;
  height: 16px;
}

.copied-content {
  display: flex;
  align-items: center;

  img {
    margin-right: 4px;
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0 4px;
}

.item {
  padding: 0 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  margin: 0 8px 8px 0;
  color: #2c2e57;
  border: 1px solid #c7c8cc;

  &.inactive {
    color: #c7c8cc;
    background: #f4f4f7;
    border: 0;
  }
}

.btn-activate,
.btn-deactivate {
  padding: 0;
  width: 110px;
  text-align: center;

  &:focus {
    outline: 0;
  }
}

.btn-copy {
  cursor: pointer;
}

.btn-empty-create-payment-link {
  width: auto;
  margin-top: 32px;
  margin-right: auto;
  padding: 8px 24px;
}

.img-copy {
  display: inline-block;
}
