@import '../../styles/base';

.container {
  width: 100%;

  > label {
    white-space: nowrap;
    display: block;
    font: 400 13px/13px $font-family;
    color: $text-light-color;
    margin-bottom: 8px;

    &.error {
      color: $alert-color;
    }

    span {
      color: $placeholder-color;
    }
  }

  .textarea-wrapper {
    @extend %border;
    height: 117px;
    padding: 16px;

    &:focus-within {
      border-color: $purple;
    }

    > textarea {
      font: 400 14px/14px $font-family;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border-width: 0px;
      resize: none;
      caret-color: $blue;
      letter-spacing: 0.02em;
    }
  }
}
