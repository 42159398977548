@import '../../styles/base';

.filters {
  @extend %border-bottom;
  display: flex;
  height: 52px;
  align-items: center;
  margin: 8px 0;

  a {
    font: 600 13px/12px $font-family;
    text-transform: uppercase;
    color: $dark-gray;
    text-decoration: none;
    padding: 10px 0;

    &:not(:last-child) {
      margin-right: 36px;
    }

    &:hover,
    &.active {
      color: #4456dd;
    }
  }

  :last-child {
    margin-left: auto;
  }
}
