@import '../../styles/colors';
@import '../../styles/base';

.container {
  display: flex;
  flex-direction: column;
  margin: 48px auto;
  width: 300px;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 0 auto 48px;
  }
}

.form {
  width: 100%;

  > h2 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 13px;
    color: #000;
  }

  fieldset {
    margin-bottom: 16px;

    > * {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}

.remember-me {
  margin-top: 16px;
}

.forgot-password {
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  margin-top: 24px;
  display: block;
}
