@import '../../styles/base';

.labeled-container {
  font: 600 14px/16px $font-family;
  letter-spacing: 0.02em;
  color: $dark-gray;

  > label {
    display: block;
    font: 400 13px/13px $font-family;
    margin-bottom: 16px;
    letter-spacing: 0.02em;
    color: $text-light-color;

    &.space-small {
      margin-bottom: 8px;
    }
  }
}
