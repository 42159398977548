@import '../../styles/base';

.empty-state-container {
  min-width: 960px;
  width: 100%;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.small {
    height: 212px;
  }

  .headline {
    color: $dark-gray;
    font: 600 20px/24px $font-family;
    display: flex;

    > img {
      margin-right: 16px;
      background-color: $light-gray;
      border-radius: 4px;
    }
  }

  .hint {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    font: 400 15px/28px $font-family;
    color: $text-medium-color;

    a {
      text-decoration: none;
    }

    &.left-padding {
      padding-left: 40px;
    }
  }
}
