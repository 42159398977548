@import '../../styles/base';

.table {
  @extend %table;

  thead {
    tr {
      th {
        &.period {
          width: 157px;
        }

        &.status {
          text-align: center;
          width: 100px;
        }

        &.amount {
          text-align: right;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0 4px;
        font: 600 13px/16px $font-family;

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }
      }
    }
  }
}
