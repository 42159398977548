@use "sass:math";
@import '../../styles/base';

.profile-popup-container {
  position: absolute;
  left: 16px;
  bottom: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 16px 0;
    box-shadow: 0 4px 16px $shadow-color;
    border-radius: 6px;

    > * {
      color: $dark-gray;
      text-decoration: none;
    }

    > *:first-child {
      @extend %border-bottom;
      font: 600 16px/28px $font-family;
      margin-bottom: 8px;
      padding: 4px 24px 12px 24px;
    }

    > *:not(:first-child) {
      font: 600 14px/18px $font-family;
      cursor: pointer;
      padding: 4px 24px;

      &:hover {
        color: $link-color;
        background-color: $super-light-gray;
      }
    }
  }

  .arrow-border {
    width: 0;
    height: 0;
    border-left: math.div($arrow-width, 2) + 1 solid transparent;
    border-right: math.div($arrow-width, 2) + 1 solid transparent;
    border-top: $arrow-height + 1 solid $separator-color;

    .arrow {
      width: 0;
      height: 0;
      position: relative;
      left: math.div(-$arrow-width, 2);
      border-left: math.div($arrow-width, 2) solid transparent;
      border-right: math.div($arrow-width, 2) solid transparent;
      border-top: $arrow-height solid $white;
    }
  }
}
