@import '../../styles/base';

.trigger {
  .trigger-item {
    position: relative;

    .arrow {
      display: none;
      position: absolute;
      width: 0;
      height: 0;
      z-index: 1;

      &.down {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;
        transform: translate(0, 4px);
      }

      &.up {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $white;
        transform: translate(4px, -32px);
      }
    }

    .arrow-border {
      display: none;
      position: absolute;
      width: 0;
      height: 0;

      &.down {
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid $separator-color;
        transform: translate(-5px, 3px);
      }

      &.up {
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $separator-color;
        transform: translate(-1px, -34px);
      }
    }
  }

  .popup {
    background: none;
    display: none;
    position: absolute;
    z-index: 1;
    box-shadow: 0 4px 16px $shadow-color;
    border-radius: 6px;
    max-width: 375px;

    @media only screen and (max-width: 320px) {
      max-width: 300px;
    }

    @media only screen and (min-width: 321px) and (max-width: 390px) {
      max-width: 328px;
    }

    &.down {
      transform: translate(-50%, 30px) translateX(12px);
    }

    &.up {
      transform: translate(-50%, -100%) translate(12px, -14px);
      width: auto;
    }

    .inner {
      background: $white;
      border-radius: 6px;
      padding: 24px;
    }
  }
}

.trigger:hover {
  .popup,
  .trigger-item .arrow,
  .trigger-item .arrow-border {
    display: initial;
  }
}
