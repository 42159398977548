@import './styles/reset';
@import './styles/base';
@import './styles/font';

* {
  box-sizing: border-box;

  &:focus {
    outline: 0;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: $white;
}

#root {
  height: 100%;
  margin: 0 auto;
  background-color: $white;

  > div {
    min-height: 100%;
    display: flex;
    flex: 1;

    > div {
      min-height: 100%;
      display: flex;
      flex: 1;
      align-items: flex-start;
    }
  }
}

body {
  overflow-y: scroll;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $base-font-size;
  color: $text-color;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  object-fit: contain;
  display: block;
}

button {
  cursor: pointer;
}

a {
  color: $link-color;
}

input {
  font-family: $font-family;
  appearance: none;
  padding: 0;
}

.btn {
  @extend %btn;
}

.main-form-title {
  font: 600 20px/1 $font-family;
  margin-bottom: 40px;
  color: $dark-gray;
  letter-spacing: 0.02em;
}

.main-greetings {
  font: 600 20px/32px $font-family;
  margin-bottom: 16px;
}

.main-desc {
  margin: 0 auto 16px;
  font-size: 15px;
  line-height: 22px;
  color: $dark-gray;
}

.error {
  color: #e53935;
  font-size: 14px;
  line-height: 16px;
  text-indent: 8px;
}

.select-box {
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    white-space: nowrap;
    font-size: 13px;
    line-height: 13px;
    color: $text-light-color;
    margin-bottom: 8px;

    &.label-error {
      color: $alert-color;
    }
  }

  select {
    border: 1px solid #dfe1e5;
    border-radius: 6px;
    height: 48px;
    color: $dark-gray;
    width: 100%;
    padding: 0 16px;
    appearance: none;
    font: 14px/48px $font-family;
    background: url('./assets/icon-chevron-down.svg') 91% no-repeat;
    background-size: 24px;

    &.select-error {
      border: 1px solid $alert-color;
      background: url('./assets/icon-chevron-down-pink.svg') 91% no-repeat;
      background-size: 24px;
    }
  }

  .error-wrapper {
    margin-top: 8px;
    background: $light-pink url('./assets/icon-exclamation-pink.svg') 16px 8px
      no-repeat;
    background-size: 16px;
    padding: 8px 8px 8px 40px;

    p {
      font: 400 13px/16px $font-family;
      color: $alert-color;
    }
  }
}

.mono {
  font-family: $font-family-mono;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

::placeholder {
  color: $placeholder-color;
  font-weight: 300;
}
