@import '../../styles/base';

.redemption-container {
  display: inline-block;

  > i {
    &:first-child {
      font: 600 13px/22px $font-family;
    }

    &:nth-of-type(2) {
      margin-left: 4px;
    }
  }

  &.finished {
    color: $cerise;
  }
}
