@import '../../styles/colors';
@import '../../styles/base';

.desc-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .desc {
    font: 400 13px/22px $font-family;
    color: $text-light-color;
  }

  .popup-content {
    em {
      font: 600 13px/16px $font-family;
    }

    ul {
      margin-top: 8px;
    }

    li {
      font: 400 13px/22px $font-family;
      color: $text-light-color;
      list-style-type: disc;
      margin-left: 22px;
    }
  }
}
