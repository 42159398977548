@import '../../styles/base';

.layout {
  @extend %setting-layout;
}

.container {
  @extend %setting-container;
  max-height: 700px;
  display: flex;
  flex-wrap: wrap;

  > section {
    @extend %section;

    > * {
      margin-bottom: 16px;
    }

    &.fees {
      @extend %border-bottom;
      padding-bottom: 12px;

      > div {
        display: flex;
        flex-wrap: wrap;
        margin: -2px 0px;
      }
    }

    &.payout-schedule {
      font: 400 14px/14px $font-family;
      padding-top: 16px;

      > label {
        font: 400 13px/13px $font-family;
        color: $text-light-color;
      }

      > label:not(:first-of-type) {
        margin-top: 16px;
      }

      > div {
        display: flex;
        align-items: center;

        > p {
          &.period {
            @extend %period;
          }
        }
      }
    }
  }
}
