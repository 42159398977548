@import '../../styles/base';

.fee-view-container {
  background-color: $super-light-gray;
  text-align: center;
  border-radius: 6px;
  padding: 24px 16px;
  margin: 2px 2px;
  height: 106px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  > p {
    font: 400 18px/18px $font-family;
    color: $dark-gray;

    > strong {
      font: 400 30px/30px $font-family;
    }

    &:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  i {
    margin: 0px 8px;
    color: $live-color;
  }

  .fixed-fee {
    margin-top: 2px;
  }

  .desc {
    font: 400 13px/22px $font-family;
    color: $disable-color;
    margin-top: 8px;
  }
}
