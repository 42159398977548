@import '../../styles/base';

.layout {
  background-color: $background-gray;
  min-height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px 0px;
}

.contact {
  margin: o auto;
  padding: 24px 0px;
  width: 100%;
  max-width: 670px;
  font: 15px/28px $font-family;
  color: $text-dark-color;

  h2 {
    @extend %headline-h1;
    margin: 0 auto 32px;
    text-align: center;

    &.no-desc {
      margin-bottom: 64px;
    }
  }

  p {
    @extend %body-large-text;
    margin: 0 auto 64px;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    .field {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }

    .fields {
      width: 100%;
    }

    .row {
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin: 0;
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 16px;
      margin-top: 32px;
      width: 100%;
      text-align: center;

      .action {
        width: 210px;
        height: 60px;
        border-radius: 6px;
      }
    }

    label {
      @extend %body-caption;
      text-align: left;

      color: $text-light;

      .required {
        color: $wild-strawberry;
      }
    }

    input,
    select,
    textarea {
      height: 48px;
      width: 100%;
      border-radius: 6px;
      border: 1px solid $ghost;
      padding: 17px 16px;
      color: $text-dark;
      font-size: 14px;
      line-height: 14px;
    }

    select {
      padding: 0 16px;
      height: 48px;
      line-height: 48px;
      background: #fff url('../../assets/icon-select-primary.svg') right center
        no-repeat;
      background-position: calc(100% - 16px) center;
      background-size: 16px;
      appearance: none;
    }

    textarea {
      height: 144px;
    }

    &.preview {
      gap: 0;

      .row {
        flex-direction: column;
        flex: 1;
        gap: 0;
      }

      .field {
        gap: 24px;
        margin: 0;
        border-bottom: 1px solid $lines;
        padding: 24px 0;
        flex-direction: row;
      }

      .actions {
        margin-top: 64px;
      }

      label {
        @extend %headline-h5;
        flex-shrink: 0;
        line-height: 28px;
        width: 208px;
      }

      input,
      select,
      textarea {
        @extend %body-text;
        border-color: $background-gray;
        padding: 0;
        height: auto;
        background: transparent;
      }

      input {
        width: 100%;
      }

      textarea {
        width: 10px;
        height: 0px;
        opacity: 0;
        visibility: hidden;
      }

      .textarea {
        @extend %body-text;
        flex: 1;
        text-align: left;
      }
    }
  }
}

.error-wrapper {
  margin: 8px auto;
  background: $error-light url('../../assets/icon-exclamation-pink.svg') 16px
    8px no-repeat;
  background-size: 16px;
  padding: 8px 8px 8px 40px;
  border-radius: 4px;

  p {
    margin: 0;
    font: 600 13px/16px var(--font-family) !important;
    color: $error !important;
  }
}
