@import '../../styles/base';

.top-bar {
  display: flex;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 24px;

  > h1 {
    font: 600 28px/36px $font-family;
  }

  > *:last-child {
    margin-left: auto;
    width: auto;
    padding: 0px 18px;
  }
}

.table {
  @extend %table;
  min-width: 960px;

  thead {
    tr {
      th {
        text-align: left;

        &.redemptions {
          text-align: right;
        }

        &.coupon {
          width: 300px;
        }
      }
    }
  }
}

button {
  &.create-coupon {
    width: auto;
    margin-top: 32px;
    margin-right: auto;
    padding: 8px 24px;
  }
}
