@import '../../styles/base';

.modal-background {
  display: block;
  position: fixed;
  background: rgba(35, 28, 69, 0.8);
  backdrop-filter: blur(8px);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px;
    background-color: $white;
    border-radius: 6px;
    width: 512px;

    &.test {
      &::before {
        content: url('../../assets/test-ribbon.svg');
        width: 70px;
        height: 70px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .modal-description {
      > *:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    > div,
    > fieldset {
      &:first-child {
        h3 {
          font: 600 17px/30px $font-family;
          color: #2c2e57;
          margin-bottom: 8px;
        }

        p {
          font: 400 13px/22px $font-family;
          color: $text-light-color;
        }
      }

      &:not(:first-child) {
        margin-top: 32px;
      }

      &.body {
        > div,
        > fieldset > div {
          &:not(:first-child) {
            margin-top: 8px;

            > label {
              margin-top: 8px;
            }
          }
        }

        > fieldset {
          margin-top: 16px;
        }
      }

      &.modal-footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        > button {
          width: auto;
          min-width: 104px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 24px;
          margin-left: 8px;
        }
      }
    }

    fieldset {
    }
  }

  input {
    font-weight: 600;
  }
}
