@import '../../styles/base';

tr {
  td {
    font: 400 13px/22px $font-family;
    color: $dark-gray;
    padding: 0 24px;

    &.amount {
      font: 600 13px/16px $font-family;
      text-align: right;
      letter-spacing: 0.02em;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
      }
    }

    &.discount {
      font: 300 12px/20px $font-family;

      img {
        display: inline;
        margin-right: 6px;
        vertical-align: top;
      }
    }

    &.mono {
      font: 300 13px/22px $font-family-mono;
      color: $text-light-color;
    }
  }

  &.order {
    cursor: pointer;

    &:hover {
      background-color: $super-light-gray;
    }

    > td {
      &:last-child {
        width: 164px;
        white-space: nowrap;

        > time {
          > span:last-child {
            margin-left: 16px;
          }
        }
      }
    }
  }
}

.kind-placeholder {
  width: 16px;
  height: 16px;
}
