@import '../../styles/base';

.container {
  width: 100%;
  display: flex;
  align-items: flex-end;

  *:first-child {
    flex: 1;
  }
}

.mask-btn {
  width: 100px;
  min-width: 100px;
  margin-left: 16px;
}
